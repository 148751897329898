<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        fixed-header
        :items="impagadosInforme"
        :headers="headers"
        show-select
        class="fixed-checkbox"
        item-key="Identificador"
        v-model="selected"
        :single-select="false"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        checkbox-color="secondary"
      >

        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="impagadosInforme"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.Identificador="{item}">
          {{ item.Identificador }}
          <v-btn small icon color="error" :to="`/impagados/control?Identificador=${item.Identificador}`">
            <v-icon small>mdi-currency-eur-off</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.deuda="{item}">
          <v-chip label>{{item.deuda}} €</v-chip>
        </template>

        <template v-slot:item.reclamable="{item}">
          <v-icon small color="success" v-if="item.reclamable" >mdi-checkbox-marked-circle-outline</v-icon>
          <v-icon small color="error" v-else >mdi-close-circle-outline</v-icon>
        </template>
        <template v-slot:item.dptoJuridico="{item}">
          <v-icon small color="success" v-if="item.dptoJuridico" >mdi-checkbox-marked-circle-outline</v-icon>
          <v-icon small color="error" v-else >mdi-close-circle-outline</v-icon>
        </template>

        <!-- <template v-slot:item.CESCE="props">
          <v-edit-dialog
            :return-value.sync="props.item.CESCE"
            persistent
            large
            cancel-text="Cancelar"
            save-text="Guardar"
          >
            {{ props.item.CESCE }}
            <template v-slot:input>
              <v-text-field
                v-model="props.item.CESCE"
                label="Editar"
                filled
                hide-details
              ></v-text-field>
            </template>
          </v-edit-dialog>
      </template> -->
      </v-data-table>
    </v-card>

    <v-card-actions class="d-flex justify-center ">

      <v-btn large color="primary" @click.stop="exportar">
        <v-icon left>mdi-download</v-icon>
        Exportar
      </v-btn>
      <!-- <v-btn large text>
        Siguiente
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn> -->
    </v-card-actions>

    <LoadingSteps :hide-overlay="false" v-if="checksPanel" :value="checks" />
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, jsonToCsv } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    LoadingSteps: () => import("@/components/LoadingSteps.vue"),
  },
  data() {
    return {
      checksPanel: true,
      checks: {},

      impagados: [],
      facturas: [],
      listadoMensajes: [],

      loading: true,
      inlineFilters: {},
      selected: [],
      headers: [
        { text: "Cliente", value: "denominacion" },
        { text: "Identificador", value: "Identificador" },
        { text: "Canal", value: "CanalAgente" },

        { text: "Nº Facturas", value: "nFacturas" },
        { text: "Estado", value: "estado" },

        { text: "Deuda total", value: "deuda", cellClass : 'pa-2 text-no-wrap font-weight-bold' },
        { text: "Deuda fraccionada", value: "deudaFra" },
        { text: "Deuda aplazada", value: "deudaApl" },
        { text: "Deuda impagada", value: "deudaImp" },

        { text: "Comentario", value: "comentario",  },

        { text: "Reclamable", value: "reclamable", dataType: "bool", align: 'center' },
        { text: "Monitorio", value: "dptoJuridico", dataType: "bool", align: 'center'  },
        { text: "CESCE", value: "cesce_temp" },
      ].map((header) => ({
        class: header.class || "text-no-wrap sticky-header",
        cellClass: header.cellClass || "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  methods: {
    getFacturas() {
      return new Promise(async (resolve, reject) => {
        this.$set(this.checks, "facturas", {
          progress: "loading",
          texto: "Cargando facturas",
        });

        const { data: facturas } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/facturacion.php`,
          method: "GET",
          params: {
            quitarRemesadas: 1,
            token: this.$store.getters.getJwtEmpresa,
            codigoContrato: this.codigoContrato,
          },
        });
        // console.log(facturas.map(({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro }) => ({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro })))
        this.facturas = facturas
          .map((f) => {
            switch (Number(f.CodigoSituacionCobro)) {
              case -2: f.estado = "Gesico"; break;
              case -3: f.estado = "Pérdidas"; break;
              case 0:
                f.estado = "Sin Especificar";
                break;
              case 1:
                f.estado = "Pendiente";
                break;
              case 2:
                f.estado = "Cobrado";
                break;
              case 3:
                f.estado = "Impagado";
                break;
              case 4:
                f.estado = "Remesado";
                break;
              case 5:
                f.estado = "Carta Aviso";
                break;
              case 6:
                f.estado = "Carta Corte";
                break;
              case 7:
                f.estado = "Cortado";
                break;
              case 8:
                f.estado = "Cobrado norma 57";
                break;
              case 9:
                f.estado = "Cancelado por abono";
                break;
              case 10:
                f.estado = "Carta Corte 1";
                break;
              case 11:
                f.estado = "Carta Deuda";
                break;
              case 12:
                f.estado = "Fraccionado";
                break;
            }
            f.denominacion =
              f.RazonSocialTitular ||
              [f.Apellido1Titular, f.Apellido2Titular, f.NombreTitular]
                .filter((i) => !!i)
                .join(" ");

            f.futuro =
              f.estado == "Pendiente" ||
              parseDate(f.FechaComienzoRemesa, true) >= new Date().getTime();
            f.aplazado =
              f.estado == "Pendiente" &&
              parseDate(f.FechaComienzoRemesa, true) <= new Date().getTime();

            if (f.NFracciones && f.NumeroFraccion == null) return;
            if (f.estado == "Cancelado por abono" || f.estado == "Impagado")
              return;
            if (f.ImporteTotal <= 0) return;

            return f;
          })
          .filter((x) => !!x);

        this.checks.facturas.progress = true;

        resolve();
      });
    },
    getImpagados() {
      return new Promise(async (resolve, reject) => {
        this.$set(this.checks, "impagados", {
          progress: "loading",
          texto: "Cargando impagados",
        });
        this.porcentajes = {};

        const impagados = await Promise.all([
          axios({
            url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
            method: "GET",
            params: {
              token: this.$store.getters.getJwtEmpresa,
              menuTab: "actual",
            },
          }),
          // axios({
          //   url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
          //   method: "GET",
          //   params: {
          //     token: this.$store.getters.getJwtEmpresa,
          //     menuTab: "historico",
          //   },
          // }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'fracTransferencia' } }),
        ]);

        this.impagados = parseImpagados(
          impagados.map((i) => i.data).flat()
        ).filter(
          (f) =>
            (f.NFracciones == null && f.NumeroFraccion == null) ||
            (f.NFracciones != null && f.NumeroFraccion > 0)
        );

        this.checks.impagados.progress = true;

        resolve();
      });
    },
    getListadoMensajes() {
      return new Promise(async (resolve, reject) => {

        this.$set(this.checks, 'mensajes', {
          progress: 'loading',
          texto: 'Cargando últimos mensajes'
        })

        const { data } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
          method: 'GET',
          params: {
            token: this.$store.getters.getJwtEmpresa
          }
        })
        this.listadoMensajes = data;

        this.checks.mensajes.progress = true;
        resolve();
      })
    },
    removeSelected() {
      if (!confirm('¿Seguro que quieres eliminar estas entradas del informe, esta acción no se puede deshacer?')) return;
      this.impagados = this.impagados.filter((item) => {
          return this.selected.findIndex(i => i.Identificador == item.Identificador) < 0; // Returns true for items not found in b.
      });
      this.facturas = this.facturas.filter((item) => { 
          return this.selected.findIndex(i => i.Identificador == item.Identificador) < 0; // Returns true for items not found in b.
      });
    }
  },
  computed: {
    impagadosInforme() {
      if (this.loading) return [];
      const sumarDeuda = (a, b) => {
        let importe = parseFloat(Number(b.ImporteTotal)).toFixed(2) * 1;

        if (!this.impTab) {
          if ((b.futuro) && b.SerieFactura == 'FRAC')
            a.deudaFra = (importe + a.deudaFra).toFixed(2) * 1;
          else if (b.aplazado)
            a.deudaApl = (importe + a.deudaApl).toFixed(2) * 1;
          else if (b.estado == 'Impagado')
            a.deudaImp = (importe + a.deudaImp).toFixed(2) * 1;
        } else if (b.historico) {
            a.deudaRec = (importe + a.deudaRec).toFixed(2) * 1;
        }

        a.deuda = parseFloat((a.deudaFra * 1) + (a.deudaApl * 1) + (a.deudaImp * 1) + (a.deudaRec * 1)).toFixed(2) * 1
        return a;
      }

      const comentarios = Object.create(null)

      const impagadosInforme = [...this.impagados, ...this.facturas].reduce((r, a) => {
        r[a.Identificador] = r[a.Identificador] || { ...a, Identificador: a.Identificador, CanalAgente: a.CanalAgente, SubAgente: a.SubAgente, IdCliente: a.IdCliente, denominacion: a.denominacion, deudaFra: 0, deudaImp: 0, deudaApl: 0, deudaRec: 0, deuda: 0, nFacturas: 0, estados: new Set(), reclamable: true };
          comentarios[ a.Identificador ] = comentarios[ a.Identificador ] || {}

        r[a.Identificador] = sumarDeuda( r[a.Identificador], a )

        r[a.Identificador].nFacturas++

        if (a.amenazaCorte || a.fechaCorteEstimada) {
          comentarios[ a.Identificador ].corte = `BAJA POR IMPAGO EL ${parseDate( a.amenazaCorte || a.fechaCorteEstimada )}`
        }
        if (a.dptoJuridico) {
          comentarios[ a.Identificador ].dptoJuridico = `EN MONITORIO`
          r[a.Identificador].reclamable = false
        }
        if (a.situacionContrato == 'Baja') {
          r[a.Identificador].reclamable = false
        }
        
        r[a.Identificador].estados.add(a.situacionContrato)

        return r
      }, Object.create(null));

      const stripHtmlToText = (html) =>
{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let res = tmp.textContent || tmp.innerText || '';
    res.replace('\u200B', ''); // zero width space
    res = res.trim();
    return res;
}

      for (const [key, com] of Object.entries(comentarios)) {
        impagadosInforme[key].estado = Array.from(impagadosInforme[key].estados).join(', ')
        try {
          com.ultimoMensaje = stripHtmlToText(this.listadoMensajes.find(m => m.Identificador == key).mensaje)
        } catch(e) {}
        impagadosInforme[key].comentario = Object.values(com).filter(x => x).join(' || ') || null
      }

      return Object.values(impagadosInforme).filter(({ deuda }) => deuda > 0).sort((a, b) => b.deuda - a.deuda)
    },
    exportar(items) {
      const getDatatype = (key) =>
        (this.headers.find((h) => h.value == key) || {}).dataType;

      if (!Array.isArray(items))
        items = this.impagadosInforme.filter((con) =>
          Object.entries(con).every(([key, value]) =>
            perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
          )
        );

      const csvHeaders = [
        { key: "denominacion", alias: 'Cliente' },
        { key: "Identificador" },
        { key: "CanalAgente" },
        { key: "SubAgente" },
        { key: "deuda", alias: 'Deuda total', dataType: "number" },
        { key: "deudaImp", alias: 'Deuda impagada', dataType: "number" },
        { key: "deudaApl", alias: 'Deuda aplazada', dataType: "number" },
        { key: "deudaFra", alias: 'Deuda fraccionada', dataType: "number" },
        { key: "comentario" },
        { key: "reclamable", dataType: 'bool' },
        { key: "cesce_temp", alias: 'CESCE', dataType: 'bool' },
        { key: "dptoJuridico", alias: 'MONITORIO', dataType: 'bool' },
      ];

      jsonToCsv(
        items,
        csvHeaders,
        "IMPAGADOS_INFORME_" + parseDate(Date.now(), false, false)
      );
    },
  },
  async mounted() {
    await Promise.all([this.getFacturas(), this.getImpagados(), this.getListadoMensajes()]);
    this.loading = false;
  },
  watch: {
    loading(val) {
      if (val) this.checksPanel = true;
      else {
        setTimeout(() => {
          this.checksPanel = false;
          this.checks = {};
        }, 1000);
      }
    },
  },
};
</script>
