var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.impagadosInforme,"headers":_vm.headers,"show-select":"","item-key":"Identificador","single-select":false,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [10, 25, 50],
      },"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
      var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.impagadosInforme},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.Identificador",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.Identificador)+" "),_c('v-btn',{attrs:{"small":"","icon":"","color":"error","to":("/impagados/control?Identificador=" + (item.Identificador))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-currency-eur-off")])],1)]}},{key:"item.deuda",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.deuda)+" €")])]}},{key:"item.reclamable",fn:function(ref){
      var item = ref.item;
return [(item.reclamable)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")])]}},{key:"item.dptoJuridico",fn:function(ref){
      var item = ref.item;
return [(item.dptoJuridico)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-center "},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.exportar($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Exportar ")],1)],1),(_vm.checksPanel)?_c('LoadingSteps',{attrs:{"hide-overlay":false,"value":_vm.checks}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }